<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <h1 class="title">Mining</h1>
        <div class="card">
          <div class="card-header">
            <p class="card-header-title">CPU (XMRig)</p>
          </div>
          <div class="card-content">
            <template v-if="xmrInstalled == undefined">
              <b-skeleton></b-skeleton>
            </template>
            <template v-if="xmrInstalled == false">
              <p>This app is not installed.</p>
            </template>
            <template v-if="xmrInstalled == true">
              <p>Status: offline</p>
            </template>
          </div>
          <footer
            class="card-footer"
            :style="{
              padding: '16px',
            }"
            key="xmrFooter"
            v-if="xmrInstalled != undefined"
          >
            <b-button
              class="is-primary"
              :style="{
                backgroundColor: '#1062FE',
              }"
              key="xmrInstall"
              v-if="xmrInstalled == false"
              @click="installXMR"
              :loading="xmrLoading"
            >
              Install ({{ latestXMRVersion }})
            </b-button>
            <b-button
              class="is-primary"
              :style="{
                backgroundColor: '#1062FE',
              }"
              key="xmrUninstall"
              v-if="xmrInstalled == true"
              @click="uninstallXMR"
              :loading="xmrLoading"
            >
              Uninstall
            </b-button>
          </footer>
        </div>

        <br />

        <div class="card">
          <div class="card-header">
            <p class="card-header-title">GPU (PheonixMiner)</p>
          </div>
          <div class="card-content">
            <template v-if="pheonixInstalled == undefined">
              <b-skeleton></b-skeleton>
            </template>
            <template v-if="pheonixInstalled == false">
              <p>This app is not installed.</p>
            </template>
            <template v-if="pheonixInstalled == true">
              <p>Status: offline</p>
            </template>
          </div>
          <footer
            class="card-footer"
            :style="{
              padding: '16px',
            }"
            key="daggerFooter"
            v-if="pheonixInstalled != undefined"
          >
            <b-button
              class="is-primary"
              :style="{
                backgroundColor: '#1062FE',
              }"
              key="daggerInstall"
              v-if="pheonixInstalled == false"
            >
              Install ({{ latestPheonixVersion }})
            </b-button>
            <b-button
              class="is-primary"
              :style="{
                backgroundColor: '#1062FE',
              }"
              key="daggerUninstall"
              v-if="pheonixInstalled == true"
            >
              Uninstall
            </b-button>
          </footer>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Mining",
  components: {},
  data() {
    return {
      xmrInstalled: undefined,
      pheonixInstalled: undefined,
      xmrLoading: false,
      latestXMRVersion: undefined,
      latestPheonixVersion: undefined,
    };
  },
  methods: {
    installXMR() {
      this.xmrLoading = true;
      window.coms.link
        .installXMR()
        .then(async () => {
          console.log("done");
          this.xmrInstalled = await window.coms.link.hasXMR();
          this.xmrLoading = false;
        })
        .catch((err) => {
          alert(err.message);
          this.xmrLoading = false;
        });
    },
    uninstallXMR() {
      this.xmrLoading = true;
      window.coms.link
        .uninstallXMR()
        .then(async () => {
          console.log("done");
          this.xmrInstalled = await window.coms.link.hasXMR();
          this.xmrLoading = false;
        })
        .catch((err) => {
          alert(err.message);
          this.xmrLoading = false;
        });
    },
  },
  asyncComputed: {
    async xmrInstalled() {
      return await window.coms.link.hasXMR();
    },
    async pheonixInstalled() {
      return await window.coms.link.hasPheonix();
    },
    async latestXMRVersion() {
      log("fetching XMR version");
      const response = await axios({
        method: "GET",
        url: "/xmrig",
      });
      log("got XMR version");
      return response.data.version;
    },
    async latestPheonixVersion() {
      log("fetching Pheonix version");
      const response = await axios({
        method: "GET",
        url: "/pheonix",
      });
      log("fetching Pheonix version");
      return response.data.version;
    },
  },
};
</script>
