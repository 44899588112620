var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"hero"},[_c('div',{staticClass:"hero-body"},[_c('h1',{staticClass:"title"},[_vm._v("Mining")]),_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-content"},[(_vm.xmrInstalled == undefined)?[_c('b-skeleton')]:_vm._e(),(_vm.xmrInstalled == false)?[_c('p',[_vm._v("This app is not installed.")])]:_vm._e(),(_vm.xmrInstalled == true)?[_c('p',[_vm._v("Status: offline")])]:_vm._e()],2),(_vm.xmrInstalled != undefined)?_c('footer',{key:"xmrFooter",staticClass:"card-footer",style:({
            padding: '16px',
          })},[(_vm.xmrInstalled == false)?_c('b-button',{key:"xmrInstall",staticClass:"is-primary",style:({
              backgroundColor: '#1062FE',
            }),attrs:{"loading":_vm.xmrLoading},on:{"click":_vm.installXMR}},[_vm._v(" Install ("+_vm._s(_vm.latestXMRVersion)+") ")]):_vm._e(),(_vm.xmrInstalled == true)?_c('b-button',{key:"xmrUninstall",staticClass:"is-primary",style:({
              backgroundColor: '#1062FE',
            }),attrs:{"loading":_vm.xmrLoading},on:{"click":_vm.uninstallXMR}},[_vm._v(" Uninstall ")]):_vm._e()],1):_vm._e()]),_c('br'),_c('div',{staticClass:"card"},[_vm._m(1),_c('div',{staticClass:"card-content"},[(_vm.pheonixInstalled == undefined)?[_c('b-skeleton')]:_vm._e(),(_vm.pheonixInstalled == false)?[_c('p',[_vm._v("This app is not installed.")])]:_vm._e(),(_vm.pheonixInstalled == true)?[_c('p',[_vm._v("Status: offline")])]:_vm._e()],2),(_vm.pheonixInstalled != undefined)?_c('footer',{key:"daggerFooter",staticClass:"card-footer",style:({
            padding: '16px',
          })},[(_vm.pheonixInstalled == false)?_c('b-button',{key:"daggerInstall",staticClass:"is-primary",style:({
              backgroundColor: '#1062FE',
            })},[_vm._v(" Install ("+_vm._s(_vm.latestPheonixVersion)+") ")]):_vm._e(),(_vm.pheonixInstalled == true)?_c('b-button',{key:"daggerUninstall",staticClass:"is-primary",style:({
              backgroundColor: '#1062FE',
            })},[_vm._v(" Uninstall ")]):_vm._e()],1):_vm._e()])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title"},[_vm._v("CPU (XMRig)")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title"},[_vm._v("GPU (PheonixMiner)")])])}]

export { render, staticRenderFns }